export enum Icon {
  alert = 'alert',
  book = 'book',
  radioAntenna = 'radio-antenna',
  puzzle = 'puzzle',
  target = 'target',
  question = 'question',
  warning = 'warning',
  user = 'user',
  trophy = 'trophy',
  students = 'students',
  speedometer = 'speedometer',
  school = 'school',
  scale = 'scale',
  numberBlocks = 'number-blocks',
  keyLabel = 'key-label',
  flowchart = 'flowchart',
  chart = 'chart',
  calculator = 'calculator',
  blackboard = 'blackboard',
  report = 'report',
  activityDesigner = 'activity-designer',
  assessmentDesigner = 'assessment-designer',
  emailOpen = 'email-open',
  email = 'email',
  house = 'house',
  menu = 'menu',
  pageFirst = 'page-first',
  pagePrevious = 'page-previous',
  pageNext = 'page-next',
  pageLast = 'page-last',
  info = 'info',
  cancel = 'cancel',
  reunion = 'reunion',
  music = 'music',
  fitness = 'fitness',
  manual = 'manual',
  growth = 'growth',
  checkbox = 'check-box',
  placement = 'placement',
  weeklyProgress = 'weekly-progress',
  weeklyScores = 'weekly-scores',
  calendar = 'calendar',
  form = 'form-icon',
  chevron = 'chevron-right',
  customized = 'customized',
  training = 'training',
  configure = 'configure',
  goalLine = 'goal-line',
  repeat = 'repeat',
  add = 'add',
  dashboards = 'dashboards',
  upstartCode = 'upstart-code',
  usage = 'stopwatch',
  usageBoth = 'usage-both',
  calendarWhite = 'calendar-white',
  locked = 'locked',
  unlocked = 'unlocked',
  incognito = 'incognito',
  download = 'download',
  move = 'move',
  plus = 'plus',
  qrCode = 'qr-code',
  revert = 'revert',
  trashRed = 'trash-red',
  userStars = 'user-stars',
  scaleZoom = 'scale-zoom',
  programs = 'programs-icon',
  sortAscending = 'sortAscending',
  sortDescending = 'sortDescending',
  link = 'link',
  starFull = 'star-full',
  starEmpty = 'star-empty',
  campConsonant = 'camp-consonant',
  checkmark = 'checkmark',
  bellBlue = 'bell-blue',
  bellRed = 'bell-red',
  back = 'back',
  forward = 'forward',
  arrowRight = 'arrow-right',
  volume = 'volume',
  pause = 'pause',
  curriculetBook = 'curriculet-book',
  laptop = 'laptop',
  pdf = 'pdf',
  video = 'video',
  reading = 'reading-icon',
  weeklyReading = 'weekly-reading',
  assignBooks = 'book-multiple',
  masteryScores = 'mastery-scores',
  playlist = 'playlist',
  visible = 'visible',
  notVisible = 'not-visible',
  fail = 'fail',
  settingsBlue = 'settings',
  settingsRed = 'setting',
  filtersFunnel = 'filters-funnel',
  menuTabs = 'menu-tabs',
  logoutBlue = 'logout-blue',
  logoutRed = 'logout',
  share = 'share',
  save = 'save',
  start = 'start',
  stop = 'stop',
  grabber = 'reorder-grabber',
  downArrow = 'down-arrow',
  resetPassword = 'password-lock',
  edit = 'edit',
  metadata = 'metadata',
  chechboxEmpty = 'checkbox-empty',
  newStar = 'new-star',
  checklistMenu = 'checklistMenu',
  lightBulb = 'light-bulb',
  activityReport = 'activity-report',
  createThumbnail = 'create-thumbnail',
  pauseCircle = 'pause-circle',
  restart = 'restart',
  supportIcon = 'support-icon',
  search = 'search',
  key = 'key',
  families = 'families',
  export = 'export',
  usersCircle = 'users-circle',
}
