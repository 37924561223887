import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon } from './icon.type';

@Component({
  selector: 'cl-icon',
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() icon: Icon;
  @Input() size = '32px';
}
